footer {
  background: var(--color-bg-variant);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-light);
}