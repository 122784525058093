.container.testimonials__container {
  position: relative;
  width: 40%;
  padding-bottom: 4rem;
}

.translation__buttons {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}

.translation__buttons .btn {
  width: 3.5rem;
  padding: 0.5rem 1rem;
}

.translation__buttons .btn:first-child {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-right: 0px;
}

.translation__buttons .btn:last-child {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-left: 0px;
}

.client__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.client__document {
  margin-top: 1rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }

  .client__review {
    width: var(--container-width-sm);
  }
}
